const INTER = [{ space_src: 'baidujs', space_type: 'inters', space_id: 'u6921186', space_js: '//yfcpsb.yuncsnet.com/production/lgf/common/o/vpp-i/resource/f.js' }];
const DIALOG = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6921187', space_js: '//yfcpsb.yuncsnet.com/source/mhg/p/w/common/qqj/i.js' }];
const BANNER = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6921188', space_js: '//yfcpsb.yuncsnet.com/site/nih/openjs/qx/r/common/r/kk.js' }];
const NATIVE_1 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6921189', space_js: '//yfcpsb.yuncsnet.com/common/oj/common/ir/source/y/resource/ssl/r.js' }];
const NATIVE_2 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6921190', space_js: '//yfcpsb.yuncsnet.com/common/g/resource/ba/openjs/j_q/common/kkjh.js' }];
const NATIVE_3 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6921191', space_js: '//yfcpsb.yuncsnet.com/site/h/cb_kr/static/ll_kl.js' }];

/** 广告位配置 */
export const SPACE_MAP = {
    Home_inter: INTER,
    Home_dialog: DIALOG,
    Home_banner: BANNER,
    Main_native: NATIVE_1,
    Classroom_native: NATIVE_2,
    Wikipedia_cat_native: NATIVE_1,
    Wikipedia_dog_native: NATIVE_2,
    Wikipedia_other_native: NATIVE_3,
    ConstellationList_banner: BANNER,
    ConstellationList_native: NATIVE_1,
    ArticleDetail_inter: INTER,
    ArticleDetail_banner: BANNER,
    ArticleDetail_native: NATIVE_1,
    PetDetail_inter: INTER,
    PetDetail_banner: BANNER,
    PetDetail_native: NATIVE_1,
    Mood_dialog: DIALOG,
};
